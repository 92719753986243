.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: var(--white);
    transition: box-shadow 0.3s ease;
}

.header-shadow {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
    z-index: 1000;
}

header nav {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1200px) {
    header nav{
      display: none;
    }
    header .menu-bar{
        display: flex;
    }
    .header h1{
        font-size: 1.25em;
        margin: 10px 25px;
    }
}
@media (min-width: 1199px) {
    header .menu-bar{
        display: none;
    }
    header nav{
        display: flex;
        margin: 10px 0;
    }
    .header h1{
        font-size: 2em;
        margin: 10px 25px;
    }
    nav:last-child{
        margin-right: 50px;
    }
}

nav button, nav a {
    margin: 0 15px;
}
header .menu-bar button .column-tab span{
    color: black;
    width: 40px;
    border: 2px black solid;
    margin: 5px 0;
    border-radius: 32px;
}
header .menu-bar button .column-tab{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.fullscreen-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(207, 204, 190);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    transition: all 0.3s ease-in-out;
}
.fullscreen-menu img{
    width: 90%;
    margin: 15vh 0;
}

.menu-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border: black 1px solid;
    color: #CFCCBE;
    border-radius: 16px;
}

.menu-nav button,
.menu-nav a {
    font-size: 24px;
    padding: 10px 20px;
    color: #CFCCBE;
    background: none;
    border: none;
}

.menu-nav span{
    color: black;
}

.close-button {
    position: absolute;
    top: 20px;
    right: 30px;
    font-size: 32px;
    color: black;
    background: none;
    border: none;
}

