:root{
    --font1: 48px;
    --font2: 1.1em;
    --font3: 1.85em;
    --font4: 0.75em;
    --black: black;
    --white: #CFCCBE;
}

.highlighted-text {
    background-color: rgba(0, 71, 255, 0.25);
    padding: 4px 10px -2px 10px;
    line-height: 0.8;
    display: inline-block;
    position: relative;
    animation: highlighted 3s ease-in-out infinite;
}
.highlighted-text-button{
    background-color: rgba(0, 71, 255, 0.25);
    padding: 4px 10px -2px 10px;
    line-height: 0.8;
    display: inline-block;
    position: relative;
}
.highlighted-text::before {
    content: '';
    position: absolute;
    background-color: rgba(0, 71, 255, 0.25);
    top: 70%;
    left: 0;
    right: 0;
    height: 10px;
    z-index: -1;
    animation: highlighted 5s ease-in-out infinite;
}
.selector-button.highlighted-button{
    cursor: default;
}
.selector-button.highlighted-button:hover {
    background-color: var(--white);
    color: var(--black);
}
@media (max-width: 1200px) {
    .cards-container{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }
    .cards-container .card{
        width: 90%;
        margin-bottom: 10%;
        transform: none;
    }
    #experience-section .row{
        flex-direction: column;
    }
    #experience-section .selector-container{
        width: 100%;
        padding-right: 0;
    }
    #experience-section .row .wrapper-color{
        width: auto;
    }
    .experience-panel h2{
        font-size: var(calc(--font4*2));
        text-align: center;
    }
    .experience-panel p{
        text-align: left;
    }
    .experience-panel .experience-links a{
        margin: 1em 0.5em;
    }
    .experience-variable{
        font-size: var(calc(--font4/2));
    }
    .experience-panel .experience-image{
        width: 75%;
    }
}
@media (max-width: 600px) {
    #top-section{
        width: 90%;
        height: 100% ;
        margin:0 auto;
        padding-top: 30%;
        text-align: center;
    }
    #top-section h2 span{
        font-size: var(--font3);
        text-align: center;
    }
    #top-section p{
        font-size: var(--font2);
        margin: 25px 0;
    }
    #top-section img{
        margin-top: 25vh;
        width: 100%;
        height: 100%;
    }
    .card p{
        font-size: var(--font4);
    }
    #experience-section #middle-bar{
        margin: 2em 0;
    }
    #experience-section h3{
        font-size: var(--font3);
    }
    .experience-panel p{
        text-align: left;
        font-size: var(--font4);
    }
    #about-section h3{
        font-size: var(--font3);
        width: inherit;
    }
    #cards h3{
        font-size: var(--font3);
    }
    #about-section{
        width: 90%;
        height: 100%;
        margin: auto;
        padding: 5% 0;
    }
    #about-section .frame{
        width: 90%;
        height: 90%;
    }
    .frame h3{
        font-size: var(--font3);
    }
    .frame p{
        font-size: var(--font4);
        width: 90%;
    }
    #contact-section p{
        font-size: var(--font4);
        display: flex;
        align-items: center;
        width: 70%;
    }
    #contact-section .row{
        justify-content: space-between;
    }
    #contact-section .row:last-child{
        justify-content: flex-end;
    }
}
@media (min-width: 1199px) {
    .cards-container{
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        margin: 5% 0;
    }
}
@media (min-width: 601px) {
    #top-section{
        height: 100% ;
        margin:0 auto 0 auto;
        padding-top: 10%;
    }
    #top-section p{
        width: 60%;
        text-align: center;
    }
    #top-section img{
        width: 95vw;
        height: 100%;
        margin: 40vh 50px 0 50px;
    }
    #top-section h2 span{
        font-size: var(--font1);
        text-align: center;
    }
    #about-section .frame{
        margin: 0 5%;
    }
    .card{
        margin: 0 2%;
        width: 100%;
    }
    #experience-section h3{
        font-size: var(--font1);
    }
    #cards h3{
        font-size: var(--font1);
    }
    #about-section{
        margin: 0 50px;
        width: auto;
        padding: 10% 0;
    }
    #about-section h3{
        font-size: var(--font1);
    }
    #about-section .frame{
        width: auto;
        height: auto;
    }
    .frame p{
        width: 85%;
    }
    #contact-section p{
        margin-left: 5%;
        width: 80%;
    }
    #contact-section .row{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
    }
}
.selector-container{
    width: 30%;
    margin: 20px 20px 0 0;
    padding-right: 5px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

@keyframes highlighted {
    0% {
        background-color: rgba(0, 71, 255, 0.25);
    }
    50% {
        background-color: rgba(0, 71, 255, 0.45);
    }
    100% {
        background-color: rgba(0, 71, 255, 0.25);
    }
}
@-webkit-keyframes tracking-in-expand {
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}
@keyframes tracking-in-expand {
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
    }
    40% {
        opacity: 0.6;
    }
    100% {
        opacity: 1;
    }
}
@keyframes float {
    0%, 100% {
        transform: translate(0px, 0px);
    }
    50% {
        transform: translate(3px, -3px);
    }
}
.tracking-in-expand {
     animation: tracking-in-expand 1s ease-out both;
 }
.wrapper{
    width: 150vw;
    display: flex;
    justify-content: space-between;
    gap: 20em;
}
.column{
    animation: scroll 10s linear infinite;
    width: 50vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.column:hover{
    animation-play-state: paused;
}
.column a, .column button {
    position: relative;
    width: 50px;
    height: 54px;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: flex-start;
    transition: width 0.5s ease, height 0.5s ease;
}

.column a:hover, .column button:hover {
    width: 200px;
    height: 54px;
}

.column a .tag, .column button .tag {
    font-size: 0.8em;
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 0.5s ease, transform 0.5s ease;
    display: block;
    z-index: -1;
}

.column a:hover .tag, .column button:hover .tag {
    z-index: 2;
}

.column a:hover i, .column button:hover i {
    z-index: -1;
}

.column a i, .column button i {
    z-index: 2;
}

.column a:hover i, .column button:hover i {
    transform: translateX(100%);
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.column a:hover .tag, .column button:hover .tag {
    transform: translateX(0%);
    opacity: 1;
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.column a span, .column button span {
    align-items: center;
    display: flex;
}
@keyframes scroll {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}
.sticky-image {
    width: 95vw;
    height: auto;
    transition: position 0.3s ease;
}

button{
    font-size: 20px;
    font-weight: 500;
    font-family: "Unbounded", sans-serif;
    width: fit-content;
    padding: 16px 8px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.5s ease-in-out;
    color: black;

    border-radius: 16px;
    border: none;

    background-color: transparent;
}
body::-webkit-scrollbar {
    display: none;
}