footer{
    margin: 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width: 600px) {
    footer p{
        font-size: var(--font4);
    }
}