@property --angle{
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}
.wrapper-color{
    width: 70%;
    height: 100%;
    padding: 1px;
    margin: 20px 0;
    position: relative;
}
.wrapper-color::after, .wrapper-color::before{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-radius: 18px 0 18px 0;
    z-index: -1;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.5);
}
@media (min-width: 600px) {
    .wrapper-color::after, .wrapper-color::before{
        background: conic-gradient(from var(--angle), transparent 80%, black);
        animation: spin 7.5s linear infinite;
    }
}

.wrapper-color::before{
    filter: blur(1.5em);
    opacity: 0.5;
}
.wrapper-color .experience-panel {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 16px 0 16px 0;
    background-color: var(--white);
}

.experience-image {
    width: 50%;
    object-fit: cover;
    border-radius: 8px;
    margin: 0 auto;
}

.experience-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.experience-title {
    margin: 20px auto;
}

.experience-description {
    margin: 0 auto 20px auto;
    width: 90%;
}

.experience-links {
    display: flex;
    margin: 0 auto;
}

.experience-links a {
    margin: 20px;
}

.experience-variable {
    margin: 20px 0 0 20px;
    color: rgba(0, 0, 0, 0.5);
}
@keyframes spin {
    from{
        --angle: 0deg;
    }
    to{
        --angle: 360deg ;
    }
}
