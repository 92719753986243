@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@200..900&display=swap');
body, html {
  margin: 0;
  font-family: "Unbounded", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #CFCCBE;
  font-size: 1.1em;
  height: 100%;
  scroll-behavior: smooth !important;
}
* {
  cursor: none;
}
