@media (max-width: 1200px) {
    section{
        margin: 0 7.5%;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
    }
}

@media (min-width: 1199px) {
    section{
        margin: 0 50px;
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
    }
}