.container {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 5% 0 2% 0;
}
.wrapper-gallery {
    flex-grow: 1;
    margin: 1%;
    position: relative;
    width: clamp(100px, 280px, 45%);
    height: 25vh;
    overflow: hidden;
    border-radius: 18px;
    z-index: 1;
    cursor: pointer;
}

.wrapper-gallery img {
    will-change: transform;
    width: 100%;
    object-fit: cover;
    transition: transform 0.1s ease;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(0);
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}
.large-image {
    max-width: 80%;
    max-height: 80%;
    border-radius: 10px;
}
