.custom-cursor {
    position: fixed;
    width: 45px;
    height: 45px;
    pointer-events: none;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 9999;
    transform: translate(-50%, -50%);
    transition: transform 0.5s ease-out;
}
@media (hover: none) {
    .custom-cursor {
        display: none;
    }
}