.selector-button{
    display: flex;
    align-items: center;
    justify-content: center;

    height: 70px;
    width: 100%;
    background-color: transparent;
    border-radius: 16px;
    border: 1px var(--black) solid;

    margin-bottom: 5px;
    font-size: 0.8em;
    font-family: "Unbounded", sans-serif;

    text-decoration: none;
    color: var(--black);
    transition: 0.5s ease-in-out;


}
.selector-button:hover{
    transition: background-color 0.5s ease-in-out;
    background-color: var(--black);
    color: var(--white);
}