.card {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    border-radius: 16px;
    border: black 1px solid;
    box-shadow: 10px 5px 5px black;

    transition: transform 0.5s ease-out;
    backface-visibility: hidden;
    perspective: 1000px;
    transform: rotateY(180deg);
    transform-style: preserve-3d;
}
.card-front{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.card-back{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black);
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    border-radius: 16px;
    backface-visibility: hidden;
    transform: rotateY(180deg);
}
.card .card-back p{
    text-align: center;
    font-weight: bold;
    width: 100%;
}
.card.flipped {
    transform: rotateY(0deg);
}
.card p{
    margin: 0 auto;
    width: 95%;
    line-height: 1.2;
}
.card button, .card a{
    margin: 20px 0;
}
.highlighted {
    background-color: rgba(0, 71, 255, 0.25);
    padding: 0;
    line-height: 1;
    display: inline-block;
    position: relative;
}

.highlighted::before {
    content: '';
    position: absolute;
    background-color: rgba(0, 71, 255, 0.25);
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    z-index: -1;
}
