.button{
    font-size: 20px;
    font-weight: 500;
    font-family: "Unbounded", sans-serif;
    width: fit-content;
    padding: 16px 8px;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: 0.5s ease-in-out;
}
.button.contour{
    color: black;

    border-radius: 16px;
    border: black 2px solid;

    background-color: transparent;

}
.button.fond{
    color: #CFCCBE;

    border-radius: 16px;
    border: black 2px solid;

    background-color: black;
}
.button.default{
    color: black;

    border-radius: 16px;
    border: none;

    background-color: transparent;
}
.button span{
    margin: 0 10px;
}
.button.default:hover{
    transition: color 0.5s ease-in-out;
    color: rgba(0, 0, 0, 0.45);
}
.button.contour:hover{
    transition: background-color 0.5s ease-in-out;
    background-color: black;
    color: #CFCCBE;
}
.button.fond:hover{
    transition: background-color 0.5s ease-in-out;
    background-color: #CFCCBE;
    color: black;
}